import React from "react"
import Service from "../components/section/service/index"

export default function Page404() {
  return (
    <section className="service h100">
      <div className="container">404 not found</div>
    </section>
  )
}
